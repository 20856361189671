<template>
  <div class="policyDetails_wrapper">
    <div class="insurance_info">
      <van-cell-group>
        <van-cell
          class="my_cell"
          title="保单信息"
          :value="status"
          title-class="product_title"
          title-style="font-weight:bold"
          value-class="title_state"
          size="large"
        />
        <!-- 产品信息 -->
        <van-cell
          title="产品名称"
          :value="policyDetail.productName"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />
        <van-cell
          title="保单号码"
          :value="policyDetail.policyNo"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />

        <van-cell
          title="起保时间"
          :value="policyDetail.effectiveDate"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />

        <van-cell
          title="产品保费"
          :value="`${policyDetail.totalPremium}元`"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />

        <van-cell
          title="保障计划"
          :value="policyDetail.planCode"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />

        <!-- 投保人信息 -->
        <van-cell value="投保人信息" class="title_second" size="large" />
        <van-cell
          title="姓名"
          :value="policyHolderDetailVO.name"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />
        <van-cell
          title="证件类型"
          value="身份证"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />
        <van-cell
          title="证件号码"
          :value="policyHolderDetailVO.idNumber"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />
        <van-cell
          title="手机号码"
          :value="policyHolderDetailVO.telephone"
          title-class="details"
          value-class="details_state"
          class="header"
          size="large"
        />
        <!-- 被保人信息 -->
        <van-cell value="被保人信息" class="title_second" size="large" />
        <div v-for="(item, index) in coverCustomers" :key="index">
          <van-cell
            :value="`被保人${formatNumber(index)}`"
            class="applicant"
            size="large"
          />
          <van-cell
            title="姓名"
            :value="item.name"
            title-class="details"
            value-class="details_state"
            class="header"
            size="large"
          />
          <van-cell
            title="证件类型"
            value="身份证"
            title-class="details"
            value-class="details_state"
            class="header"
            size="large"
          />
          <van-cell
            title="证件号码"
            :value="item.idNumber"
            title-class="details"
            value-class="details_state"
            class="header"
            size="large"
          />
          <van-cell
            title="手机号码"
            :value="item.mobile"
            title-class="details"
            value-class="details_state"
            class="header"
            size="large"
          />
        </div>
      
	    <!-- 批改信息 -->
		<div v-show="this.policyDetail.status == 'AVAILABLE' || this.policyDetail.status == 'CORRECTED'">
			<van-cell value="被保人信息(新)" class="title_second" size="large" />
			<van-field
			  class="my_insured_field"
			  v-model="correctName"
			  name="correctName"
			  label="姓名"
			  placeholder="请输入被保人姓名"
			  label-class="my_insured_label"
			  required
			/>
			<van-cell
			  title="证件类型"
			  value="身份证"
			  title-class="details"
			  value-class="details_state"
			  class="header"
			  size="large"
			/>
			<van-field
			  class="my_insured_field"
			  v-model="correctIdCardNumber"
			  name="correctIdCardNumber"
			  label="证件号码"
			  placeholder="请输入被保人证件号码"
			  label-class="my_insured_label"
			  required
			/>
			<van-field
			  class="my_insured_field"
			  v-model="correctMobile"
			  name="correctMobile"
			  label="手机号码"
			  placeholder="请输入手机号码"
			  label-class="my_insured_label"
			  required
			/>
		</div>
	  </van-cell-group>
    </div>
    <footer>
	<div v-show="this.policyDetail.status != 'WITHDRAWALED'" class="footerdiv">
      <div class="ipt" v-show="this.policyDetail.status == 'INSURED'" @click="cancelInsuranceDialog">撤保</div>
	  <div class="ipt" v-show="this.policyDetail.status == 'AVAILABLE' || this.policyDetail.status == 'CORRECTED'" @click="saveCorrectInsurance">批改</div>
	  <div class="ipt" @click="download">下载保单</div>
	 </div>
	 <a v-show="false" ref="downloadLink" type="text"></a>
      <!-- <div class="ipt">修改</div>
      <!-- <div class="ipt" @click="download">下载保单</div>
      <div class="ipt">电子发票</div> -->
    </footer>
  </div>
</template>

<script>
import './PolicyDetails.less'
import { Cell, CellGroup, Dialog,Field,Notify } from 'vant'
import { getPolicyDetailData, downloadPolicy,saveCorrect,downloadCorrect } from '../../api/api'
import { undoPolicy } from '../../api/api'
import Nzh from 'nzh'
import { verifyCardNo, isMobile,getCardNoBirthday,getCardNoSex,getBirthdayFromIdCard } from "../../utils/verifyUtils";
import wx from 'weixin-js-sdk'
import {
    baseConfig
} from '../../utils/request'
export default {
  name: 'PolicyDetails',
  data() {
    return {
	  correctMobile:'',
	  errMsg: '',
	  correctName:'',
	  correctIdCardNumber:'',
      // 客服电话
      tel: '400-099-6778',
      // 保单详情信息
      policyDetail: {},
      policyHolderDetailVO: {},
      //被保人集合
      coverCustomers: [],
      statusList: {
        'WAIT_TO_INSURED': '待投保',
        'INSURED': '已投保',
        'AVAILABLE': '已生效',
        'CORRECTED': '已批改',
        'WITHDRAWALED': '已撤保'
      }
    }
  },
  components: {
    'van-cell-group': CellGroup,
    'van-cell': Cell,
	'van-field': Field,
  },
  computed: {
    // 将1,2,3...返回为一,二,三....
    formatNumber() {
      return index => {
        //使用简体中文,另外有 Nzh.hk -- 繁体中文
        let nzhcn = Nzh.cn
        //转中文小写 >> 十万零一百一十一
        return nzhcn.encodeS(index + 1)
      }
    },
    status() {
      return this.statusList[this.policyDetail.status];
    }
  },
  methods: {
    async getPolicyDetail() {
      const { policyId } = this.$route.query;
      console.log(this.$route.query)
      const { data } = await getPolicyDetailData({
        policyId: policyId,
        openId: JSON.parse(localStorage.getItem('loginInfo')).openId,
        unionId: JSON.parse(localStorage.getItem('loginInfo')).unitId
      })
      console.log(data);
      this.policyDetail = data.policyVO;
      this.policyHolderDetailVO = data.policyHolderDetailVO;
      this.coverCustomers = data.insuredVoList;
    }
    ,
    // 撤保确认弹出框
    cancelInsuranceDialog() {
      const { policyId } = this.$route.query;
      let info = {
        policyId: policyId,
        unionId: JSON.parse(localStorage.getItem('loginInfo')).unitId,
      }
      Dialog.confirm({
        title: '提示',
        message: '确认要撤保吗?',
        confirmButtonColor: '#28cc77'
      }).then(() => {
        this.cancelInsurance(info)
      }).catch(err => {
        console.log(err)
      })
    },
    // 撤保
    async cancelInsurance(info) {
      const data = await undoPolicy(info)
      if (data.code === 200) {
        Dialog.alert({
          title: '提示',
          message: '撤保成功,费用稍后退还',
          confirmButtonColor: '#28cc77'
        }).then(() => {
          this.$router.go(-1);
        })
      }
      else {
        Dialog.confirm({
          title: '提示',
          message: `该保单撤保失败，\n请联系客服热线${this.tel}进行退保`,
          confirmButtonText: '拨打',
          cancelButtonText: '知道啦',
          confirmButtonColor: '#28cc77'
        }).then(() => {
          // 拨打电话
          window.location.href = `tel:${this.tel}`
        }).catch(err => {
          console.log(err)
        })
      }



      // const { code, success } = data
      // if (code === 200) {
      //   if (!success) {
      //     Dialog.confirm({
      //       title: '提示',
      //       message: `该保单已经生效，\n请联系客服热线${this.tel}进行退保`,
      //       confirmButtonText: '拨打',
      //       cancelButtonText: '知道啦'
      //     }).then(() => {
      //       // 拨打电话
      //       window.location.href = `tel:${this.tel}`
      //     }).catch(err => {
      //       console.log(err)
      //     })
      //   } else {
      //     await Dialog.alert({
      //       title: '提示',
      //       message: '撤保成功',
      //       confirmButtonColor: '#28cc77'
      //     })
      //   }
      // }
    }
   ,
   async saveCorrectInsurance(){
     if (!this.correctName) {
       this.errMsg = `请输入被保人姓名`;
	   Notify(this.errMsg);
	   return;
     }
     const personalIdCardNum = verifyCardNo(this.correctIdCardNumber)
     if (personalIdCardNum) {
       this.errMsg = personalIdCardNum;
	   Notify(this.errMsg);
	   return;
     }
	 const personalPhoneNum = isMobile(this.correctMobile);
	 if (!personalPhoneNum) {
	   this.errMsg = '手机号格式不正确';
	   Notify(this.errMsg);
	   return;
	 }
	 const { policyId } = this.$route.query;
	 const data = {
		 birthDate:getBirthdayFromIdCard(this.correctIdCardNumber),
		 gender:getCardNoSex(this.correctIdCardNumber) == 0?'M':'F',
		 idType:'ID',
		 idNumber:this.correctIdCardNumber,
		 mobile:this.correctMobile,
		 name:this.correctName,
		 policyId:policyId,
		 unitId:JSON.parse(localStorage.getItem('loginInfo')).unitId
	 }
	 const responseData = await saveCorrect(data);
	 if (responseData.code == 200) {
	   Notify('申请成功');
	   this.settlementInfo = {
	     correctIdCardNumber: '',
	     correctName: '',
	     correctMobile: ''
	   }
	 }else{
		 Notify(responseData.message || '批改失败');
	 }
   }
   ,
   download: function () {
	   var that = this
       //policyNo
       // window.open(Setting.apiBaseURL + 'order/v1/policy/download/'+ policyNo, '_blank');
	    // this.loading = true;
	   // let formData = new FormData();
	   // formData.append('policyNo',policyId);
	   // formData.append('unionId',JSON.parse(localStorage.getItem('loginInfo')).unitId);
	   var ua = window.navigator.userAgent.toLowerCase();
	   //先判断是否微信浏览器
	   if (ua.match(/MicroMessenger/i) == "micromessenger") {
	       //再判断一下是否在小程序里
	       if (/(Android)/i.test(navigator.userAgent)) {
	       	wx.miniProgram.getEnv((res) => {
	   			if(res.miniprogram){
	   				wx.miniProgram.navigateTo({
	   					url:`../downloadpdf/downloadpdf?url=${encodeURIComponent(that.getUrl())}`
	   				})
	   			}else{
	   				location.href = that.getUrl()
	   			}
	       		
	       	})
	       }else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
	       	location.href = that.getUrl()
	       }else{
	       	location.href = that.getUrl()
	       }
	   }else{
	      	location.href = that.getUrl()
	   }

	//    const data = {policyNo:policyNo,unionId:JSON.parse(localStorage.getItem('loginInfo')).unitId}
    //    downloadCorrect(data).then(res => {
    //        const blob = new Blob([res]);//处理文档流
    //        const downloadLink = this.$refs['downloadLink'];
    //        downloadLink.download = `${policyNo}.pdf`
    //        downloadLink.href = URL.createObjectURL(blob);
    //        downloadLink.click()
    //        downloadLink.href = '';
    //        this.loading = false;
    //    }).catch(err=>{
		  //  let errmsg = err
		  //  if(err.message){
			 //   errmsg = err.message
		  //  }
		  //  Notify(errmsg)
	   // })
   },
   getUrl(){
	   const {policyNo} = this.policyDetail
	   const url = `${baseConfig.skURL}/policy/download?policyNo=${policyNo}&unionId=${JSON.parse(localStorage.getItem('loginInfo')).unitId}`
	   return url
	   // const downloadLink = this.$refs['downloadLink'];
	   // downloadLink.download = `${policyNo}.pdf`
	   // downloadLink.href = url;
	   // downloadLink.click()
	   // downloadLink.href = '';
   }
  }
  ,
  created() {
    this.getPolicyDetail()
  }
  ,
  mounted() {
    // const {
    //     productName, //产品名称
    //     policyNo, //保单号码
    //     createTimeStr, // 起保时间
    //     endTimeStr, // 起保时间
    //     lfee, // 产品保费
    //     planCode, // 保障计划
    //     policyInsureCustomer, // 投保人姓名
    //     insureCustomerIdNumber, // 投保人身份证
    //     mobile, // 投保人手机号
    //     coverCustomers, // 被保人列表
    // } = PDdata.appPolicy;
  }
  ,
}
</script>

